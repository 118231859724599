<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 15:48:33
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-17 21:40:45
-->
<template>
  <div class="page-box">
    <div class="header flex-y center">
      <div class="avatar img-box">
        <img :src="user.wx.head_pic" alt="" />
      </div>
      <div class="nickname">{{ user.user.name }}</div>
    </div>

    <div class="menu-list" v-for="(list, index) in menu" :key="index">
      <div
        class="menu"
        :class="item.num ? 'menu--num' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="navPath(item)"
      >
        <div
          class="menu__img"
          :data-num="item.num"
          :style="`background-image: url('${item.icon}');`"
        ></div>
        <div class="menu__title">{{ item.title }}</div>
      </div>
    </div>

    <div class="warranty" @click="$router.push({ name: 'UserRepair' })"></div>
  </div>
</template>

<script>
import { userInfo } from "../../../utils/api/utils";
import { orderStatus } from "../../../utils/api/workOrder";
export default {
  data() {
    return {
      menu: [
        [
          {
            icon: require("../../../assets/images/user/menu_wallet.png"),
            title: "待付款",
            query: { status: 5 },
            pathName: "UserOrderList",
          },
          {
            icon: require("../../../assets/images/user/menu_review.png"),
            title: "待审核",
            query: { status: 0 },
            pathName: "UserOrderList",
          },
          {
            icon: require("../../../assets/images/user/menu_dispatch.png"),
            title: "待派单",
            query: { status: 1 },
            pathName: "UserOrderList",
          },
        ],
        [
          {
            icon: require("../../../assets/images/user/menu_offer.png"),
            title: "确认报价",
            query: { status: 2 },
            pathName: "UserOrderList",
          },
          {
            icon: require("../../../assets/images/user/menu_service.png"),
            title: "待维修",
            query: { status: 3 },
            pathName: "UserOrderList",
          },
          {
            icon: require("../../../assets/images/user/menu_draft.png"),
            title: "草稿箱",
            query: { status: 7 },
            pathName: "UserDraftBox",
          },
        ],
        [
          {
            icon: require("../../../assets/images/user/menu_acceptance.png"),
            title: "待验收",
            query: { status: 4 },
            pathName: "UserOrderList",
          },
          {
            icon: require("../../../assets/images/user/menu_complete.png"),
            title: "已完成",
            query: { status: 6 },
            pathName: "UserOrderList",
          },
        ],
      ],

      user: {
        user: {},
        wx: {},
      },
    };
  },

  created() {
    userInfo().then((res) => {
      this.user = res.data.data;
      this.getStatus();
    });
  },

  methods: {
    getStatus() {
      orderStatus().then((res) => {
        let statusList = res.data.data.list;
        let menu = JSON.parse(JSON.stringify(this.menu));

        for (let i = 0; i < statusList.length; i++) {
          for (let j = 0; j < menu.length; j++) {
            for (let k = 0; k < menu[j].length; k++) {
              if (statusList[i].status === menu[j][k].query.status)
                menu[j][k].num = statusList[i].number;
            }
          }
        }

        this.menu = menu;
      });
    },
    navPath(menu) {
      this.$router.push({
        name: menu.pathName,
        query: menu.query,
      });
    },
  },
};
</script>

<style src="../../../assets/css/user/index.css" scoped>
</style>